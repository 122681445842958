<template>
  <app-module-view>
    <template slot="body">
      <template v-if="dataLoaded">
        <section class="m-b-10">
          <div class="row">
            <div class="col-lg-12">
              <div class="text-right m-r-5">
                <button
                  type="button"
                  class="btn btn-success"
                  @click="save"
                >
                  <i class="fa fa-save "></i> {{ $t('buttons.save') }}
                </button>
                <app-button-delete v-if="item.id" @deleteRecord="deleteItem"></app-button-delete>
                <app-button-close :url-path=this.$route.query.originalPath></app-button-close>
              </div>
            </div>
          </div>
        </section>
        <section class="content form-horizontal">
          <div class="row">
            <div class="col-lg-8">
              <div class="card">
                <div class="card-body">
                  <section class="content form-horizontal">
                    <div class="box box-info">
                      <div class="box-body">
                        <div class="row">
                          <div class="col-lg-12">
                            <input type="hidden" v-model="item.id">
                            <div>
                              <app-input
                                v-model="item.title"
                                id="title"
                                @blur="$v.item.title.$touch()"
                                :error="$v.item.title.$error"
                                :label="$t('don.npa.title')"
                                :required="true"
                                :disabled="this.item.duplicate === true"
                              >
                              </app-input>
                            </div>
                            <div>
                              <app-input
                                v-model="item.url"
                                id="url"
                                @blur="$v.item.url.$touch()"
                                :error="$v.item.url.$error"
                                :label="$t('don.npa.url')"
                                :required="true"
                              >
                              </app-input>
                            </div>
                            <div>
                              <app-input
                                v-model="item.agency"
                                id="agency"
                                @blur="$v.item.agency.$touch()"
                                :error="$v.item.agency.$error"
                                :label="$t('don.npa.agency')"
                                :required="true"
                                :disabled="this.item.duplicate === true"
                              >
                              </app-input>
                            </div>
                            <div>
                              <app-input
                                v-model="item.client"
                                id="client"
                                @blur="$v.item.client.$touch()"
                                :error="$v.item.client.$error"
                                :label="$t('don.npa.client')"
                                :required="true"
                                :disabled="this.item.duplicate === true"
                              >
                              </app-input>
                            </div>
                            <div v-if="this.item.duplicate === true">
                              <div class="form-group"
                                   :class="{'not-editable-image': mainImage}"
                              >
                                <app-media
                                  v-if="this.mainImage"
                                  data-test="main-image"
                                  :media="this.mainImage"
                                  :width="480"
                                  :height="270"
                                >
                                </app-media>
                              </div>

                              <div v-for="(alternativeName, idx) in ['B', 'C']" :key="`item-${idx}`">
                                <div if v-if="articleAlternatives[idx + 1].title || articleAlternatives[idx + 1].image"
                                     class="form-group alternative">
                                  <app-input
                                    v-model="articleAlternatives[idx + 1].title"
                                    :id="`articleAlternatives-${idx + 1}-title`"
                                    :label="`${$t('don.npa.alternative_title')} ${alternativeName}`"
                                    disabled
                                  >
                                  </app-input>

                                  <app-input
                                    v-model="articleAlternatives[idx + 1].url"
                                    :id="`articleAlternatives-${idx + 1}-url`"
                                    :label="`${$t('don.npa.alternative_url')} ${alternativeName}`"
                                    disabled
                                  >
                                  </app-input>

                                  <label>{{ $t('don.npa.alternative_image') }} {{ alternativeName }}</label><br>
                                  <div :class="{'not-editable-image': articleAlternatives[idx + 1].image}">
                                    <app-media
                                      v-if="articleAlternatives[idx + 1].image"
                                      :data-test="`articleAlternatives-${idx + 1}-image`"
                                      :media="articleAlternatives[idx + 1].image"
                                      :width="480"
                                      :height="270"
                                    >
                                    </app-media>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div v-else>
                              <div>
                                <label>{{ $t('don.npa.image') }}</label><br>
                                <app-media-editable
                                  v-if="mainImage"
                                  data-test="main-image"
                                  :media="mainImage"
                                  :width="360"
                                  :height="202"
                                  @remove-media="removeImage[0]"
                                >
                                </app-media-editable>

                                <div class="form-group">
                                  <app-media-select-button
                                    data-test="select-button-0"
                                    @set-media="setImage[0]"
                                  >
                                  </app-media-select-button>
                                  <app-media-upload-button
                                    data-test="upload-button-0"
                                    @set-media="setImage[0]"
                                  >
                                  </app-media-upload-button>
                                </div>
                              </div>

                              <br>

                              <div>
                                <app-checkbox
                                  v-model="item.alternativesEnabled"
                                  id="alternativesEnabled"
                                  @input="enableDisableAlternatives"
                                  :label="$t('don.npa.alternating_campaigns')"
                                  :disabled="item.status > 1"
                                ></app-checkbox>
                              </div>

                              <br>

                              <div v-if="bAlternativeVisible">
                                <app-input
                                  v-model="articleAlternatives[1].title"
                                  id="articleAlternatives-one1-title"
                                  @blur="$v.articleAlternatives.$each[1].title.$touch()"
                                  :error="$v.articleAlternatives.$each[1].title.$error"
                                  :label="`${$t('don.npa.alternative_title')} B`"
                                >
                                </app-input>

                                <app-input
                                  v-model="articleAlternatives[1].url"
                                  id="articleAlternatives-one1-url"
                                  :label="`${$t('don.npa.alternative_url')}`"
                                >
                                </app-input>

                                <label>{{ $t('don.npa.alternative_image') }} B</label><br>
                                <app-media-editable
                                  v-if="articleAlternatives[1].image"
                                  data-test="articleAlternatives-one1-image"
                                  :media="articleAlternatives[1].image"
                                  :width="360"
                                  :height="202"
                                  @remove-media="removeImage[1]"
                                >
                                </app-media-editable>

                                <div class="form-group">
                                  <app-media-select-button
                                    data-test="select-button-1"
                                    @set-media="setImage[1]"
                                  >
                                  </app-media-select-button>
                                  <app-media-upload-button
                                    data-test="upload-button-1"
                                    @set-media="setImage[1]"
                                  >
                                  </app-media-upload-button>
                                </div>
                              </div>

                              <div v-if="cAlternativeVisible">
                                <app-input
                                  v-model="articleAlternatives[2].title"
                                  id="articleAlternatives-two2-title"
                                  @blur="$v.articleAlternatives.$each[2].title.$touch()"
                                  :error="$v.articleAlternatives.$each[2].title.$error"
                                  :label="`${$t('don.npa.alternative_title')} C`"
                                >
                                </app-input>

                                <app-input
                                  v-model="articleAlternatives[2].url"
                                  id="articleAlternatives-two2-url"
                                  :label="`${$t('don.npa.alternative_url')}`"
                                >
                                </app-input>

                                <label>{{ $t('don.npa.alternative_image') }} C</label><br>
                                <app-media-editable
                                  v-if="articleAlternatives[2].image"
                                  data-test="articleAlternatives-two2-image"
                                  :media="articleAlternatives[2].image"
                                  :width="360"
                                  :height="202"
                                  @remove-media="removeImage[2]"
                                >
                                </app-media-editable>

                                <div class="form-group">
                                  <app-media-select-button
                                    data-test="select-button-2"
                                    @set-media="setImage[2]"
                                  >
                                  </app-media-select-button>
                                  <app-media-upload-button
                                    data-test="upload-button-2"
                                    @set-media="setImage[2]"
                                  >
                                  </app-media-upload-button>
                                </div>
                              </div>

                              <div>
                                <button
                                  v-if="item.status < 2 && item.alternativesEnabled && !cAlternativeVisible"
                                  data-test="add-alternative"
                                  class="btn btn-sm btn-green"
                                  @click="addAlternative"
                                >
                                  {{ $t('don.npa.buttons.add_alternative') }}
                                </button>
                                <button
                                  v-if="item.status < 2 && cAlternativeVisible"
                                  class="btn btn-sm btn-danger"
                                  @click="removeAlternative"
                                  data-test="remove-alternative"
                                >
                                  {{ $t('don.npa.buttons.remove_alternative') }}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="card">
                <div class="card-body">
                  <div v-if="mode === 'update'">
                    <div class="form-group">
                      <app-checkbox
                        id="don_npa_paused"
                        v-model="paused"
                        :label="$t('don.npa.paused')"
                        :disabled="item.status === 1 || item.status === 3"
                      >
                      </app-checkbox>
                    </div>
                  </div>
                  <div v-if="mode === 'create'">
                    <div class="form-group">
                      <app-checkbox
                        id="don_npa_dividedByDays"
                        v-model="item.dividedByDays"
                        :label="$t('don.npa.divided_by_days')">
                      </app-checkbox>
                    </div>
                  </div>
                  <div>
                    <app-input
                      v-model="item.clicksLimit"
                      id="don_npa_clicksLimit"
                      @blur="$v.item.clicksLimit.$touch()"
                      :error="$v.item.clicksLimit.$error"
                      :label="$t('don.npa.clicks_limit')"
                      :required="true"
                    >
                    </app-input>
                  </div>
                  <div>
                    <app-input
                      v-model="item.clickPrice"
                      id="don_npa_clickPrice"
                      :label="$t('don.npa.click_price')"
                      :required="true"
                      :min="0"
                      :step="0.1"
                      @blur="validateClickPrice"
                    >
                    </app-input>
                  </div>

                  <div class="m-b-30">
                    <app-datepicker
                      v-model="item.publishedSince"
                      :label="$t('don.npa.published_since')"
                      id="don_npa_publishedSince"
                    >
                    </app-datepicker>
                    <app-datepicker
                      v-model="item.publishedUntil"
                      :label="$t('don.npa.published_until')"
                      id="don_npa_publishedUntil"
                    >
                    </app-datepicker>
                  </div>

                  <div>
                    <app-checkbox
                      v-model="item.algorithmOverridden"
                      id="algorithmOverridden"
                      :label="$t('don.npa.algorithm_overridden')"
                    ></app-checkbox>
                  </div>

                  <div>
                    <app-checkbox
                      v-model="item.paidCampaign"
                      id="paidCampaign"
                      :label="$t('don.npa.paid_campaign')"
                    />
                  </div>

                  <div :class="{ 'form-group': !item.schedulingEnabled }">
                    <app-checkbox
                      v-model="item.schedulingEnabled"
                      id="enableScheduling"
                      @input="toggleScheduling"
                      :label="$t('don.npa.enable_scheduling')"
                    ></app-checkbox>
                  </div>

                  <div v-if="item.schedulingEnabled">
                    <div class="form-group">
                      <app-select
                        v-model="schedulingConfig.deliveryDay"
                        :options="deliveries"
                        id="day_selection"
                        :emptyValueTitle="$t('don.npa.campaign_delivery_day')"
                      >
                      </app-select>
                    </div>

                    <div class="form-group">
                      <div class="row">
                        <div class="col-sm-6">
                          <app-datepicker
                            v-model="schedulingConfig.deliveryTimeFrom"
                            :label="$t('don.npa.campaign_delivered_from')"
                            id="don_npa_campaign_delivered_from"
                            type="time"
                          >
                          </app-datepicker>
                        </div>
                        <div class="col-sm-6">
                          <app-datepicker
                            v-model="schedulingConfig.deliveryTimeTo"
                            :label="$t('don.npa.campaign_delivered_to')"
                            id="don_npa_campaign_delivered_to"
                            type="time"
                          >
                          </app-datepicker>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="m-t-10">
                    <app-select
                      id="category"
                      :label="$t('don.npa.category')"
                      :emptyValueTitle="$t('don.npa.category_selection')"
                      v-model="item.category"
                      :options="categories"
                      disableFormGroup
                    ></app-select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </template>
      <template v-else>
        <app-preloader></app-preloader>
      </template>
    </template>
  </app-module-view>
</template>

<script>
import ModuleView from '../ModuleView'
import Input from '../form/inputs/Input'
import Checkbox from '../form/Checkbox'
import Datepicker from '../form/Datepicker'
import { minValue, maxLength, minLength, required } from 'vuelidate/lib/validators'
import NativeArticle from '../../model/DonNativePerfArticle'
import ButtonDelete from '../shared/ButtonDelete'
import ButtonClose from '../shared/ButtonClose'
import NotifyService from '../../services/NotifyService'
import Preloader from '../preloader/Preloader'
import Media from '../shared/Media'
import MediaSelectButton from '../shared/MediaSelectButton'
import MediaUploadButton from '../shared/MediaUploadButton'
import MediaEditable from '../shared/MediaEditable'
import Select from '@/components/form/select/Select'
import { CATEGORIES_LIST } from '@/model/ValueObject/NativePerfCategories'
import CoreApi from '../../api/core'
import { DELIVERIES_LIST } from '@/model/ValueObject/NativePerfDeliveries'
import moment from 'moment'

export default {
  name: 'NativePerfArticleNew',
  data () {
    return {
      mode: 'create',
      dataLoaded: true,
      item: this._.cloneDeep(NativeArticle),
      paused: false,
      categories: CATEGORIES_LIST,
      mainImage: null,
      bAlternativeVisible: false,
      cAlternativeVisible: false,
      articleAlternatives: [
        {
          name: 'A',
          title: '',
          image: null,
          url: ''
        },
        {
          name: 'B',
          title: '',
          image: null,
          url: ''
        },
        {
          name: 'C',
          title: '',
          image: null,
          url: ''
        }
      ],
      deliveries: DELIVERIES_LIST,
      schedulingConfig: {
        deliveryDay: null,
        deliveryTimeFrom: null,
        deliveryTimeTo: null
      }
    }
  },
  validations: {
    item: {
      title: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(250)
      },
      url: {
        required
      },
      agency: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(250)
      },
      client: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(250)
      },
      clicksLimit: {
        minValue: minValue(1)
      }
    },
    articleAlternatives: {
      $each: {
        title: {
          minLength: minLength(3),
          maxLength: maxLength(250)
        }
      }
    }
  },
  components: {
    appModuleView: ModuleView,
    appInput: Input,
    appCheckbox: Checkbox,
    appDatepicker: Datepicker,
    appButtonDelete: ButtonDelete,
    appButtonClose: ButtonClose,
    appPreloader: Preloader,
    appMedia: Media,
    appMediaSelectButton: MediaSelectButton,
    appMediaUploadButton: MediaUploadButton,
    appMediaEditable: MediaEditable,
    appSelect: Select
  },
  methods: {
    async save () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        NotifyService.setErrorMessage(this.$t('notify.please_fill_all_required_fields'))
        return
      }

      if (!this.validateMainImage()) {
        return
      }

      if (!this.validateAlternatives()) {
        return
      }

      if (this.item.schedulingEnabled && !this.validateSchedulingDeliveryTime()) {
        return
      }

      this.$store.dispatch('nativePerfArticle/create', this.prepareRequest(this.item))
        .then(() => {
          if (this.$store.getters['nativePerfArticle/error'] === null) {
            NotifyService.setSuccessMessage(this.$t('notify.record_was_created'))
            this.item = this.$store.getters['nativePerfArticle/detail']
            this.$router.push('/nativePerfArticle/' + this.item.id + '/edit?originalPath=' + this.$route.query.originalPath)
          } else {
            const status = this.$store.getters['nativePerfArticle/error'].status

            if (status === 400) {
              const validationErrors = this.$store.getters['nativePerfArticle/error'].validationErrors

              for (const error of validationErrors) {
                const detail = error.detail

                if (this.item.alternativesEnabled) {
                  const alternative = error.alternative
                  NotifyService.setErrorMessage(this.$t('don.npa.validation.alternative_' + detail) + ' ' + alternative + '.')
                } else {
                  NotifyService.setErrorMessage(this.$t('don.npa.validation.' + detail))
                }
              }
            } else {
              NotifyService.setErrorMessage(this.$store.getters['nativePerfArticle/error'])
            }
          }
        })
        .catch(error => console.log(error))
    },

    deleteItem () {
      this.$store.dispatch('nativePerfArticle/deleteRecord', this.item)
        .then(() => {
          if (this.$store.getters['nativePerfArticle/error'] === null) {
            NotifyService.setSuccessMessage(this.$t('notify.record_was_deleted'))
            this.$router.push(this.$route.query.originalPath)
          } else {
            NotifyService.setErrorMessage(this.$store.getters['nativePerfArticle/error'])
          }
        })
        .catch(error => console.log(error))
    },

    validateMainImage () {
      if (!this.mainImage) {
        NotifyService.setErrorMessage(this.$t('don.npa.validation.main_image_check'))
        return false
      }

      return true
    },

    validateAlternatives () {
      let valid = true

      if (this.bAlternativeVisible) {
        const title = this.articleAlternatives[1].title
        const image = this.articleAlternatives[1].image

        if (!title && !image) {
          NotifyService.setErrorMessage(this.$t('don.npa.validation.alternative_b_check'))
          valid = false
        }
      }

      if (this.cAlternativeVisible) {
        const title = this.articleAlternatives[2].title
        const image = this.articleAlternatives[2].image

        if (!title && !image) {
          NotifyService.setErrorMessage(this.$t('don.npa.validation.alternative_c_check'))
          valid = false
        }
      }

      return valid
    },

    validateSchedulingDeliveryTime () {
      const deliveredFrom = moment(this.schedulingConfig.deliveryTimeFrom, 'HH:mm')
      const deliveredTo = moment(this.schedulingConfig.deliveryTimeTo, 'HH:mm')

      if (deliveredTo.isSameOrBefore(deliveredFrom)) {
        NotifyService.setErrorMessage(this.$t('don.npa.validation.delivery_time_check'))
        return false
      }

      return true
    },

    prepareRequest (item) {
      item.damImageId = this.mainImage.id
      item.damImageFileName = this.mainImage.damMediaEmbed.filename

      if (item.alternativesEnabled) {
        item.articleAlternatives = []
        this.articleAlternatives[0].title = item.title
        this.articleAlternatives[0].url = item.url
        this.articleAlternatives[0].image = this.mainImage

        for (let idx = 0; idx < this.articleAlternatives.length; idx++) {
          const title = this.articleAlternatives[idx].title
          const image = this.articleAlternatives[idx].image

          if (!title && !image) {
            continue
          }

          const articleAlternative = {}

          articleAlternative.name = this.articleAlternatives[idx].name
          articleAlternative.url = this.articleAlternatives[idx].url

          if (title) {
            articleAlternative.title = title
          } else {
            articleAlternative.title = item.articleAlternatives[0].title
          }

          if (image && image.damMediaEmbed) {
            articleAlternative.damImageId = image.id
            articleAlternative.damImageFileName = image.damMediaEmbed.filename
          } else {
            articleAlternative.damImageId = item.articleAlternatives[0].damImageId
            articleAlternative.damImageFileName = item.articleAlternatives[0].damImageFileName
          }
          item.articleAlternatives.push(articleAlternative)
        }
      } else {
        item.articleAlternatives = []
      }

      if (this.item.schedulingEnabled) {
        this.prepareScheduling()
      }

      if (this.mode === 'create') {
        item.status = 1
      } else if (item.status === 2 || item.status === 4) {
        item.status = this.paused === true ? 4 : 2
      }

      return item
    },

    loadArticleAlternatives () {
      const articleAlternatives = this.item.articleAlternatives
      const articleAlternativesCount = articleAlternatives.length

      const imagePromises = []

      for (let idx = 0; idx < articleAlternativesCount; idx++) {
        const articleAlternative = articleAlternatives[idx]

        this.articleAlternatives[idx].title = articleAlternative.title
        this.articleAlternatives[idx].url = articleAlternative.url

        const damImageId = articleAlternative.damImageId

        if (damImageId) {
          imagePromises.push(this.fetchArticleAlternativeImage(idx, damImageId))
        }
      }

      Promise.all(imagePromises)
        .finally(result => {
          for (let idx = 1; idx < articleAlternativesCount; idx++) {
            if (idx === 1) {
              this.bAlternativeVisible = true
            }

            if (idx === 2) {
              this.cAlternativeVisible = true
            }
          }

          this.dataLoaded = true
        })
        .catch(err => console.log('Catch', err))
    },

    fetchMainImage (damImageId) {
      return CoreApi().get('/media/' + damImageId)
        .then((response) => {
          this.mainImage = response.data
          this.dataLoaded = true
        })
        .catch(error => console.log(error))
    },

    fetchArticleAlternativeImage (idx, damImageId) {
      return CoreApi().get('/media/' + damImageId)
        .then((response) => {
          if (idx === 0) {
            this.mainImage = response.data
          }

          this.articleAlternatives[idx].image = response.data
        })
        .catch(error => console.log(error))
    },

    setMainImage (medias) {
      this.mainImage = medias[0]

      if (this.alternativesEnabled) {
        this.articleAlternatives.find((item) => item.name === 'A').image = medias[0]
      }
    },

    setImageAlternativeB (medias) {
      this.articleAlternatives.find((item) => item.name === 'B').image = medias[0]
    },

    setImageAlternativeC (medias) {
      this.articleAlternatives.find((item) => item.name === 'C').image = medias[0]
    },

    removeMainImage () {
      this.mainImage = null

      if (this.alternativesEnabled) {
        this.articleAlternatives.find((item) => item.name === 'A').image = null
      }
    },

    removeImageAlternativeB () {
      this.articleAlternatives.find((item) => item.name === 'B').image = null
    },

    removeImageAlternativeC () {
      this.articleAlternatives.find((item) => item.name === 'C').image = null
    },

    enableDisableAlternatives () {
      if (this.item.alternativesEnabled) {
        this.bAlternativeVisible = true
      }

      if (!this.item.alternativesEnabled) {
        this.articleAlternatives[1].title = ''
        this.articleAlternatives[1].url = ''
        this.articleAlternatives[2].title = ''
        this.articleAlternatives[2].url = ''
        this.articleAlternatives[1].image = null
        this.articleAlternatives[2].image = null

        this.bAlternativeVisible = false
        this.cAlternativeVisible = false
      }
    },

    addAlternative () {
      this.cAlternativeVisible = true
    },

    removeAlternative () {
      this.cAlternativeVisible = false
      this.articleAlternatives[2].title = ''
      this.articleAlternatives[2].url = ''
      this.articleAlternatives[2].image = null
    },

    prepareScheduling () {
      const schedulingConfig = {}

      if (this.schedulingConfig.deliveryDay !== 0) {
        schedulingConfig.deliveryDay = this.schedulingConfig.deliveryDay
      } else {
        schedulingConfig.deliveryDay = ''
      }
      schedulingConfig.deliveryTimeFrom = moment(this.schedulingConfig.deliveryTimeFrom, 'HH:mm').utc().format('HH:mm')
      schedulingConfig.deliveryTimeTo = moment(this.schedulingConfig.deliveryTimeTo, 'HH:mm').utc().format('HH:mm')

      this.item.schedulingConfig = schedulingConfig
    },

    setSchedulingConfig () {
      const deliveryDay = this.item.schedulingConfig.deliveryDay

      if (deliveryDay) {
        this.schedulingConfig.deliveryDay = this.item.schedulingConfig.deliveryDay
      } else {
        this.schedulingConfig.deliveryDay = 0
      }

      const deliveryTimeFrom = this.item.schedulingConfig.deliveryTimeFrom
      const deliveryTimeTo = this.item.schedulingConfig.deliveryTimeTo

      this.schedulingConfig.deliveryTimeFrom = moment.utc(deliveryTimeFrom, 'HH:mm').local().format('HH:mm')
      this.schedulingConfig.deliveryTimeTo = moment.utc(deliveryTimeTo, 'HH:mm').local().format('HH:mm')
    },

    toggleScheduling () {
      if (this.item.schedulingEnabled) {
        this.schedulingConfig.deliveryDay = 0
        this.schedulingConfig.deliveryTimeFrom = this.defaultTime()
        this.schedulingConfig.deliveryTimeTo = this.defaultTime()
      } else {
        this.schedulingConfig.deliveryDay = null
        this.schedulingConfig.deliveryTimeFrom = null
        this.schedulingConfig.deliveryTimeTo = null
      }
    },

    defaultTime () {
      const now = moment()

      now.set('hour', 12)
      now.set('minute', 0)
      now.set('second', 0)
      now.set('millisecond', 0)

      return now.format('HH:mm')
    },
    validateClickPrice (price) {
      this.item.clickPrice = price.replace(',', '.')
    }
  },
  computed: {
    setImage () {
      return [
        this.setMainImage,
        this.setImageAlternativeB,
        this.setImageAlternativeC
      ]
    },

    removeImage () {
      return [
        this.removeMainImage,
        this.removeImageAlternativeB,
        this.removeImageAlternativeC
      ]
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss" scoped>
.not-editable-image {
  width: 800px;
  height: 450px;
}

.alternative {
  margin-top: 50px;
}
</style>
